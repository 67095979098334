import React, { useEffect, useState } from 'react';
import SkeletonTable from "../../components/Layout/Utilities/SkeletonTable";
import {columns, skeletonColumns} from "../Notifications/NotificationsData";
import { Button, Table, Typography } from 'antd';
import { UsDateTimeformat } from '../../utilities/extensions';
import { useClinicalReportsMutation, usePullClinicalReportsMutation, usePullLatestClinicalReportsDateMutation } from '../../features/clinicalReports/clinicalReportService';
import { defaultClinicalReportsFilter } from '../../../src/components/Schema/clinicalReportsSchema';
import { AiOutlineDownload } from 'react-icons/all';
import Icon from '@ant-design/icons';



const headerTitleStyle = (text) => {
  return <p>{text}</p>;
};

const detailsStyle = (text) => {
  return (
     <p strong className="text-color">
        {text}
     </p>
  );
};

const unifiedReportNameRender = (text) => {
  let parts = text.split("/");
  
  if (parts.length == 1) return parts[0]; 

  let dateStr = parts[0];
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);

  let namePart = parts[parts.length - 1].replace(".xlsx", "");

  return `${year}-${month}-${day}, ${namePart}`;
}

const getCurrentPeriodEnding = (date) => {
  if ("" === date){
    return "";
  }

  let dateParts = date.split("T")[0].split("-");
  let day = dateParts[2];

  if (day >= 15){
    dateParts[2] = 15;
  }
  else{
    dateParts[2] = 1;
  }

  return dateParts.join("-");
}

const handleDownloadClick = (downloadUrl) => {
  console.log(`Download URL: ${downloadUrl}`);
};

const ClinicalReports = () => {
  const [clinicalReports] = useClinicalReportsMutation();
  const [pullClinicalReports] = usePullClinicalReportsMutation();
  const [clinicalReportsLatestDate] = usePullLatestClinicalReportsDateMutation();
  const [dataSource, setDataSource] = useState([]);
  const [pageData, setPageData] = useState(defaultClinicalReportsFilter)
  const [latestDate, setLatestDate] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchLatestReportsDate = async () => {
    setLoading(true);
    try {
      const response = await clinicalReportsLatestDate({ }).unwrap();
      setLatestDate(getCurrentPeriodEnding(response));
      
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReports = async (filter) => {
    setLoading(true);
    try {
      const response = await clinicalReports({ ...filter }).unwrap();
      setDataSource(response.data);
      setPageData(response)
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  };

  const pullReports = async () => {
    setLoading(true);
    try {
      const response = await pullClinicalReports({ }).unwrap();
    } catch (error) {
      console.error('Error pulling reports:', error);
    } finally {
      setLoading(false);
    }
  }

  const onPageChange = (page, pageSize) => { 
    fetchReports( { pageNumber:page, pageSize:pageSize}); 
  }
    
  const onPullReports = async () => {
    await pullReports();
    fetchReports();
  }
  
  useEffect(() => {
    fetchReports(defaultClinicalReportsFilter);
    fetchLatestReportsDate();
  }, []);

  const columns = [
    {
      title: headerTitleStyle('Report Title'),
      dataIndex: 'unifiedReportName',
      key: 'unifiedReportName',
      render: (text) => <p className="patient-fullname">{`${unifiedReportNameRender(text)}`}</p>,
    },
    {
      title: headerTitleStyle('Team'),
      dataIndex: 'teamName',
      key: 'teamName',
      render: (text) => detailsStyle(text),
    },
    {
      title: headerTitleStyle('Date Created'),
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (text) => detailsStyle(UsDateTimeformat(text)),
    },
    {
      title: headerTitleStyle('Download'),
      key: 'azureFileName',
      dataIndex: 'azureFileName',
      render: (text) => (
        <AiOutlineDownload style={{ fontSize: '30px', color: '#255ca7' }}/>
      ),
    },
  ];

  return (
    <div>
      <h1 style={{ marginBottom: '50px' }}>Clinical Report</h1>
      
      <Button type="primary" onClick={onPullReports}>Pull Reports</Button>
      
      <p style={{ fontWeight: 'bold', color: '#255ca7', marginTop: '10px', marginBottom: '50px' }}>{`Generate the report for the period ending : ${latestDate}.`}</p>
      
      <div className="d-flex flex-column">
        <SkeletonTable loading={loading} columns={columns} active={loading} >
          <Table
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            pagination={{
              position: ['bottomRight'],
              current: pageData.pageNumber,
              total: pageData.totalCount,
              pageSize: pageData.pageSize,
              onChange:onPageChange,
            }}

          />
        </SkeletonTable>
      </div>
    </div>
  );
};

export default ClinicalReports;
