/*Object.prototype.isString = function isString() {
    return typeof this === 'string' || this instanceof String
};*/

import { format } from 'date-fns-tz';
import jwt_decode from 'jwt-decode';
import LocalTokenService from '../services/localTokenService';
import {
    MAX_CHAR_LENGTH,
    MAX_PHONE_NUMBER_LENGTH,
    MAX_STATE_LENGTH,
    MAX_ZIP_CODE_LENGTH,
} from './validationMessages';

export const isFunction = (source) => source instanceof Function;

export const isNotNullOrUndefined = (source) =>
    typeof source !== 'undefined' && source !== null;

export const isObjectAndNotNull = (source) =>
    typeof source !== 'undefined' &&
    source !== null &&
    typeof source === 'object';

export const isString = (source) =>
    (typeof source !== 'undefined' && typeof source === 'string') ||
    source instanceof String;

export const isStringAndNotEmpty = (source) =>
    typeof source !== 'undefined' &&
    (typeof source === 'string' || source instanceof String) &&
    source.trim().length > 0;

export const formatDateTo = (date, formatStr) =>
    format(new Date(date), formatStr);

export const IsArrayIncludes = (arr, value) =>
    IsArrayWithLength(arr) ? arr.includes(value) : false;

export const IsKeyTrue = (dic, key) => dic && dic[key] === true;

export const AreEqualNumbers = (n1, n2) =>
    IsNotEmpty(n1) &&
    IsNotEmpty(n2) &&
    n1.replace('+', '') === n2.replace('+', '');

export const IsArrayWithLength = (items) =>
    Array.isArray(items) && items.length > 0;

export const IsNotEmpty = (value) =>
    typeof value !== 'undefined' &&
    value != null &&
    typeof value === 'string' &&
    value.trim().length > 0;

export const IsEmptyObject = (object) =>
    !(
        IsNotUndefinedOrNotNull(object) &&
        object.constructor === Object &&
        Object.keys(object).length > 0
    );

export const IsNotUndefinedOrNotNull = (value) =>
    typeof value !== 'undefined' && value != null;

export const IsFunction = (f) =>
    f !== undefined && f !== null && typeof f === 'function';

export const IsNumberGreaterThanZero = (value) =>
    typeof value !== 'undefined' && typeof value == 'number' && value > 0;

export const ObjectKeysFilter = (source, keys) =>
    Object.keys(source)
        .filter((key) => keys.includes(key))
        .reduce((obj, key) => {
            obj[key] = source[key];
            return obj;
        }, {});

const ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
export const tokenRoles = (token) => {
    try {
        const jwt = isNotNullOrUndefined(token) ? jwt_decode(token) : undefined;
        if (isNotNullOrUndefined(jwt)) return jwt[ROLE] || [];
    } catch {
        return [];
    }
};

export const UserHasRoles = (roles, token) => {
    try {
        if (token === undefined) token = LocalTokenService.getToken().token;
        const tRoles = tokenRoles(token);
        return (
            IsArrayWithLength(tRoles) &&
            tRoles.find((r) => roles.includes(r)) !== undefined
        );
    } catch {
        return false;
    }
};

export const CurrentUserHasRoles = (roles) => {
    try {
        const token = LocalTokenService.getToken().token;
        const tRoles = tokenRoles(token);
        return (
            IsArrayWithLength(tRoles) &&
            tRoles.find((r) => roles.includes(r)) !== undefined
        );
    } catch {
        return false;
    }
};

export const formatDate = (dateString) => {
    if (typeof dateString === 'string') {
        const [year, month, day] = dateString.slice(0, 10).split('-');
        return `${day}-${month}-${year}`;
    } else return '';
};

export const UsDateformat = (dateString) => {
    if (typeof dateString === 'string') {
        const [year, month, day] = dateString.slice(0, 10).split('-');
        return `${month}-${day}-${year}`;
    } else return '';
};

export const UsDateTimeformat = (dateString) => {
    if (typeof dateString === 'string') {
        const [year, month, day] = dateString.slice(0, 10).split('-');
        const time = dateString.slice(11, 19); // Extracts the time part (HH:MM:SS)
        return `${month}-${day}-${year} ${time}`;
    } else return '';
};

export const phoneNumberValidator = (value, type) => {
    const length = type === 'phone' ? 10 : 9;
    if (!value) {
        return Promise.resolve();
    }
    if (
        (value && !isNaN(value) && type === 'zip' && value.length <= length) ||
        value === ''
    ) {
        return Promise.resolve();
    }
    if (value && !isNaN(value) && type === 'phone' && value.length === length) {
        return Promise.resolve();
    }
    return Promise.reject(MAX_PHONE_NUMBER_LENGTH);
};

export const zipCodeValidator = (value) => {
    const minLength = 5;
    const maxLength = 9;

    if (!value) {
        return Promise.resolve();
    }

    if (
        value &&
        !isNaN(value) &&
        value.length >= minLength &&
        value.length <= maxLength
    ) {
        return Promise.resolve();
    }

    return Promise.reject(MAX_ZIP_CODE_LENGTH);
};

export const stateLengthValidator = (value) => {
    if (!value) {
        return Promise.resolve();
    }
    if (value && value.length > 2) {
        return Promise.reject(MAX_STATE_LENGTH);
    }
    return Promise.resolve();
};

export const maxLengthValidator = (value) => {
    if (!value) {
        return Promise.resolve();
    }
    if (value && value.length > 35) {
        return Promise.reject(MAX_CHAR_LENGTH);
    }
    return Promise.resolve();
};
