import { baseApiService } from "../baseApiService";
import { ClinicalReportResponse, ClinicalReportRequest } from "../../models/ClinicalReportResponse";

export const clinicalReportingApi = baseApiService.injectEndpoints({
    endpoints: (builder) => ({
        clinicalReports: builder.mutation<ClinicalReportResponse, ClinicalReportRequest>({
            query: (req) => ({
                url: '/v1/ClinicalReporting',
                method: 'POST',
                body: req
            }),
        }),
        pullClinicalReports: builder.mutation<boolean, any>({
            query: (req) => ({
                url: 'v1/ClinicalReporting/pull',
                method: 'POST',
                body: req
            })
        }),
        pullLatestClinicalReportsDate: builder.mutation<string, any>({
            query: (req) => ({
                url: 'v1/ClinicalReporting/latest',
                method: 'GET',
            })
        }),
    })
})

export const { useClinicalReportsMutation, usePullClinicalReportsMutation, usePullLatestClinicalReportsDateMutation } = clinicalReportingApi